/*
 * @Date: 2023-04-10 12:58:17
 * @LastEditors: simLarry
 * @LastEditTime: 2023-04-26 12:39:07
 * @FilePath: \P1-M1-QRP-CODE\utils\hooks\useRegister.js
 */
import { useRequest } from 'ahooks';
import { fetchRequest } from '$CONFIG/Fatch.config';
import { Cookie, getBrowserVersion } from '$ACTIONS/util';
import { ApiPort } from '$ACTIONS/API';

const postRegister = ({ password, mobile, username, gender, documentId, affiliateCode = '', dateOfBirth = '', email = '', }, {isHideDOCID=false, isHideGENDER=false, isHideDOB=false}) => {
    let params = {
        password,
        mobile,
        username,
        email,
        referrerUrl: Cookie.GetCookieKeyValue('Referer') || window.location.origin,
        currentDomain: window.location.origin,
        hidBlackbox: window.ioGetBlackbox?.().blackbox || '',
        hidE2: window.E2GetBlackbox?.().blackbox || '',
        language: 'ZH-CN',
        mediaCode: Cookie.GetCookieKeyValue('Media') || null,
        // referralCode: Cookie.GetCookieKeyValue('CO_affiliate') || '',
        affiliateCode : affiliateCode,
        referralCode: affiliateCode,
        // regWebsite: '1',
        websiteId: getBrowserVersion(),
    }
    if (!isHideDOCID) {
        params.documentId = documentId;
    }
    if (!isHideGENDER) {
        params.gender = gender;
    }
    if (!isHideDOB) {
        params.dateOfBirth = dateOfBirth;
    }
    if (typeof params.lastName!=='string' || !params.lastName) {
        params.lastName = '';
    }
    return fetchRequest(ApiPort.Register, 'POST', params);
}


const useRegister = ({ onBefore, onSuccess, onError }) => {
    return useRequest(postRegister, {
        manual: true,
        onBefore: () => {
            if (typeof onBefore === 'function') {
                onBefore();
            }
            global.globalGtag('Register', 'Submit', 'Submit_Register');
            global.globalConsole("useRegister onBefore")
        },
        onSuccess: (result, params) => {
            if (typeof onSuccess === 'function') {
                onSuccess(result, params);
            }
            global.globalConsole("useRegister onSuccess", result, params)
        },
        onError: (error) => {
            if (typeof onError === 'function') {
                onError(error);
            }
            global.globalConsole("useRegister onError", error)
        },
    });
}

export default useRegister
