import React from "react";
import { Button } from 'antd';
import { useForm } from "react-hook-form";
import CSImg from '@/CSImg'
import Logo from '@/Logo';
import useLogin from '$UTILS/hooks/useLogin';
import Loading from '@/Loading';
import {getAffFromCookie} from "../../actions/util";

const HeaderForm = ({ logoImage, onBefore, onSuccess, onError, data = {} }) => {
	// const aff = '';

	const { loading, run } = useLogin({ onBefore, onSuccess, onError });
	const { register, handleSubmit, formState: { errors } } = useForm();

	const { mainSiteUrl = '', liveChatUrl = '' } = data || {};
	const affiliateCode = getAffFromCookie();

	if (affiliateCode) {
		mainSiteUrl = mainSiteUrl ? new URL(`${affiliateCode ? `?aff=${affiliateCode}` : ''}`, mainSiteUrl).href : ''
	}
	const forgetPasswordURL = mainSiteUrl ? new URL(`?action=ForgotPassword${affiliateCode? `&aff=${affiliateCode}` : ''}`, mainSiteUrl).href : ''

	const handleFinish = ({ username, password }) => {
		run({
			username,
			password,
			affiliateCode
		})
	}

	return (
		<header className="header">
			<div className="header-wrapper">
				<Logo src={logoImage} data={data} />
				<section className="header-form-wrapper">
					<form className="header-form" onSubmit={handleSubmit(handleFinish)}>
						<div className="box">
							<input type="text" className="web-input" maxLength={14} placeholder="请输入用户名" {...register("username", { required: "用户名不可为空。" })} />
							{errors.username && <span className="error-code">{errors.username.message}</span>}
						</div>

						<div className="box">
							<input type="password" className="web-input" maxLength={20} name="password" placeholder="请输入密码" {...register("password", { required: "密码不可为空。" })} />
							{errors.password && <span className="error-code">{errors.password.message}</span>}
						</div>

						<div>
							<Button className="login" htmlType='submit' disabled={loading}>登录</Button>
							{loading && <Loading />}
						</div>
					</form>
					<a onClick={() => { global.globalGtag('Style9_QRP', 'Login form', 'Login_topnav', 'event') }} href={mainSiteUrl} className="login mobile">登录</a>
					<div className="header-action">
						<a onClick={() => { global.globalGtag('Style9_QRP', 'Forget Password', 'ForgetPW_topnav', 'event') }} href={forgetPasswordURL} className={'text-link'}>忘记密码？</a>
						<a onClick={() => { global.globalGtag('Style9_QRP', 'Forget Password', 'Livechat_topnav', 'event') }} href={liveChatUrl} className="text-link" target={"_"}>
							<CSImg />
							<span>在线客服</span>
						</a>
					</div>
				</section>
			</div>
		</header>
	)

}

export default HeaderForm;
