export const setupIsHideField = (useQrpFields) => {
  const { data: qrpFieldsData } = useQrpFields({ ready: true });
  console.log('qrpFieldsData:', qrpFieldsData);
  let isHideDOCID = false, isHideGENDER = false, isHideDOB = false;
  if (qrpFieldsData && qrpFieldsData.isSuccess && qrpFieldsData.code=='QRP00001' && qrpFieldsData.qrpFields) {
    if (Object.prototype.hasOwnProperty.call(qrpFieldsData.qrpFields, 'IsShowNationalID') && typeof qrpFieldsData.qrpFields.IsShowNationalID!=='undefined') {
      isHideDOCID = !qrpFieldsData.qrpFields.IsShowNationalID;
    }
    if (Object.prototype.hasOwnProperty.call(qrpFieldsData.qrpFields, 'IsShowGender') && typeof qrpFieldsData.qrpFields.IsShowGender!=='undefined') {
      isHideGENDER = !qrpFieldsData.qrpFields.IsShowGender;
    }
    if (Object.prototype.hasOwnProperty.call(qrpFieldsData.qrpFields, 'IsShowDOB') && typeof qrpFieldsData.qrpFields.IsShowDOB!=='undefined') {
      isHideDOB = !qrpFieldsData.qrpFields.IsShowDOB;
    }
  }
  return {isHideDOCID, isHideGENDER, isHideDOB};
};