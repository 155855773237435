import moment from "moment";

/**
 * 生成生日年份的數組
 * @param {number} validAge 最小年齡，預設為 18 歲。
 * @param {number} length 生成年份的數量，預設為 65。
 * @returns {number[]} 生日年份的數組。
 */
export const getBirthdayYears = (validAge = 18, length = 65) => {
  const maxYear = moment().subtract(validAge, 'year').year(); // 獲取當前年份減去最小年齡的年份
  return Array.from({ length }, (_, index) => maxYear - index); // 生成數組
};


/**
 * 根据年份返回当月的数组
 * @param {number} year  出生年份
 * @param {number} month 出生月份
 * @returns {number[]} 当月的数组
 */
export const getBirthDayMonth = (year) => {
    let nowMonth = moment().month();
    if( moment().year() - year === 18){
        return Array.from({length:nowMonth+1}, (_, index) => index + 1);
    }else{
        return Array.from({length:12}, (_, index) => index + 1);
    }
} 

/**
 * 根據年份和月份生成當月的天數的數組
 * @param {number} year 出生的年份。
 * @param {number} month 出生的月份。
 * @returns {number[]} 當月天數的數組。
 */
export const getBirthdayDays = (year, month) => {
  let nowYear = moment().year();
  let nowMonth = moment().month(new Date().getMonth()).format('MM');
  console.log(nowYear,nowMonth);
  if (year && month) {
    let daysInMonth = moment(`${year}-${month}`, 'YYYY-MM').daysInMonth(); // 獲取當月天數
    if(nowYear - year === 18 && nowMonth === month){
        daysInMonth = moment().date();
    }
    return Array.from({ length: daysInMonth }, (_, index) => index + 1); // 生成數組
  }
  return [];
};


export function on(obj, ...args) {
  if (obj && obj.addEventListener) {
    obj.addEventListener(...(args));
  }
}

export function off(obj, ...args) {
  if (obj && obj.removeEventListener) {
    obj.removeEventListener(...(args));
  }
}
