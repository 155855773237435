import React, { useMemo } from 'react';
import { Button } from 'antd';
import { RegName, RegPassword, RegPhone, RegUserid } from '$CONFIG/common/reg';
import { useForm } from "react-hook-form";
import { getBirthdayYears, getBirthdayDays , getBirthDayMonth } from '$UTILS';
import { setupIsHideField } from "$UTILS/helpers/QrpFields";
import useQrpFields from "$UTILS/hooks/useQrpFields";
import useRegister from '$UTILS/hooks/useRegister';
import Loading from '@/Loading';
import {getAffFromCookie} from "../../actions/util";

const years = getBirthdayYears();

const Central = ({ text, title, onBefore, onSuccess, onError, cssStyleConf={} }) => {
	const affiliateCode = getAffFromCookie();

	let {isHideDOCID, isHideGENDER, isHideDOB} = setupIsHideField(useQrpFields)
	console.log('style9', isHideDOCID, isHideGENDER, isHideDOB)

	const { loading, run } = useRegister({ onBefore, onSuccess, onError })
	const { register, handleSubmit, formState: { errors }, watch, setValue , getValues} = useForm({
		defaultValues: {
			birthday: {
				year: '',
				month: '',
				day: ''
			},
			gender: 'Male'
		}
	});
	const currentYear = watch('birthday.year');
	const currentMonth = watch('birthday.month');
	const days = useMemo(() => getBirthdayDays(currentYear, currentMonth), [currentYear, currentMonth]);
	const month = useMemo(() =>getBirthDayMonth(currentYear, currentMonth), [currentYear, currentMonth]);


	const handleFormSubmit = data => {
		const { username, password, mobile, birthday, documentId, gender } = data;
		run({
			...{
				username,
				password,
				mobile: `${mobile}`,
				affiliateCode,
			},
			...(isHideDOCID ? {} : {documentId}),
			...(isHideGENDER ? {} : {gender}),
			...(isHideDOB ? {} : {dateOfBirth: `${birthday.year}-${birthday.month}-${birthday.day}`})
		}, {isHideDOCID, isHideGENDER, isHideDOB})
	};

	//驗證身分證號和生日要符合
	const validateIdAndBirthday = (v) => {
		if (v && v.length >= 15) {
			let bday = getValues('birthday.year') + getValues('birthday.month') + getValues('birthday.day');
			console.log('===bday', bday, v, v.length);
			if (v.length == 18) {
				return (bday == v.substr(6, 8));
			} else if (v.length == 15) {
				bday = bday.substr(2);
				return (bday == v.substr(6, 6));
			}
		}
		return false;
	};

	let submitCssStyle = {};
	if (cssStyleConf?.isFullWidthSubmit) {
		submitCssStyle.width = '100%';
	}
	if (cssStyleConf?.submitFontSize) {
		submitCssStyle.fontSize = cssStyleConf.submitFontSize
	}

	return (
		<section className="central">
			<div className="title">
				<h3>{title}</h3>
			</div>
			<form className="form-wrapper" onSubmit={handleSubmit(handleFormSubmit)}>
				<p className="desc" dangerouslySetInnerHTML={{ __html: text }} />
				<div className="form-block">
					<div className="login-item grid-cols-4">
						<label className="form-label">用户名</label>
						<input type="text" className="input-control col-span-3" maxLength={14} placeholder="请输入用户名" {...register("username", { required: "用户名不可为空", pattern: { value: RegName, message: "用户名必须由 6 － 14 位长度的字母或数字组成，中间不得有读音符号、空格或下划线。" }, onChange: () => { global.globalGtag('Style9_QRP', 'Registration form', 'Username_input', 'input') } })} />
						{errors.username && <label className="error-txt col-span-3 col-start-2">{errors.username.message}</label>}
					</div>
					<div className="login-item grid-cols-4">
						<label className="form-label">密码</label>
						<input type="password" className="input-control col-span-3" maxLength={20} name="password" placeholder="请输入密码" {...register("password", { required: "密码不可为空", pattern: { value: RegPassword, message: '密码必须包含6-14个字符，字符只限于使用字母和数字。（可以包含 ^#$@ 中的特殊字符）' }, onChange: () => { global.globalGtag('Style9_QRP', 'Registration form', 'Password_input', 'input') } })} />
						{errors.password && <label className="error-txt col-span-3 col-start-2">{errors.password.message}</label>}
					</div>
					<div className="login-item grid-cols-4">
						<label className="form-label">手机号码</label>
						<input disabled type="tel" className="input-control disabled" name="phone" value={'+86'}></input>
						<input type="text" className="input-control col-span-2" maxLength={11} name="mobile" id="inputPhone" placeholder="请输入手机号码" {...register("mobile", { required: "手机号码不可为空", pattern: { value: RegPhone, message: '手机号码必须是 11 位长度的数字。' }, onChange: () => { global.globalGtag('Style9_QRP', 'Registration form', 'phone_input', 'input') } })} />
						{errors.mobile && <label className="error-txt col-span-2 col-start-3">{errors.mobile.message}</label>}
					</div>
					{isHideDOB ? null : <div className="login-item grid-cols-4" data-key="DOB">
						<label className="form-label">出生日期1</label>
						<select className="input-control birthday" {...register("birthday.year", { required: true, onChange: () => { setValue("birthday.day", ""), global.globalGtag('Style9_QRP', 'Registration form', 'BirthdayYear_input', 'input') } })}>
							<option value="" disabled>年</option>
							{years.map(value => <option key={value} value={value}>{value}</option>)}
						</select>
						<select className="input-control birthday" {...register("birthday.month", { required: true, onChange: () => { setValue("birthday.day", ""), global.globalGtag('Style9_QRP', 'Registration form', 'BirthdayMonth_input', 'input') } })}>
							<option value="" disabled>月</option>
							{month.map(value => <option key={value} value={String(value).padStart(2, "0")}>{String(value).padStart(2, "0")}</option>)}
						</select>
						<select className="input-control birthday" {...register("birthday.day", { required: true, onChange: () => { global.globalGtag('Style9_QRP', 'Registration form', 'BirthdayDay_input', 'input') } })}>
							<option value="" disabled>日</option>
							{days.map(value => <option key={value} value={String(value).padStart(2, "0")}>{String(value).padStart(2, "0")}</option>)}
						</select>
						{errors.birthday && <label className="error-txt col-span-3 col-start-2">出生日期不可为空。</label>}
					</div>}
					{isHideDOCID ? null : <div className="login-item grid-cols-4" data-key="DOCID">
						<label className="form-label">身份证号</label>
						<input type="text" className="input-control col-span-3" maxLength={18} placeholder="请输入身份证号" {...register("documentId", { required: "身份证号不可为空", pattern: { value: RegUserid, message: '身份证号格式无效。' },validate:  v => validateIdAndBirthday(v) || '您的身份证号码与生日不符', onChange: () => { global.globalGtag('Style9_QRP', 'Registration form', 'cnId_input', 'input') } })} />
						{errors.documentId && <label className="error-txt col-span-3 col-start-2">{errors.documentId.message}</label>}
					</div>}
					{isHideGENDER ? null : <div className="login-item grid-cols-4" data-key="GENDER">
						<label className="form-label">性別</label>
						<select className="input-control col-span-3" id="inputState" name="gender" {...register("gender", { required: true, onChange: () => { global.globalGtag('Style10_QRP', 'Registration form', 'gender_input', 'input') } })} >
							<option value="Male">男</option>
							<option value="Female">女</option>
						</select>
					</div>}
					<Button style={submitCssStyle} htmlType='submit' className="submit" onClick={() => { global.globalGtag('Style9_QRP', 'Registration form', 'Submit_registration', 'event') }} disabled={loading}>提交</Button>
					{loading && <Loading />}
				</div>
			</form>
		</section>
	)
}

export default Central
