import React from 'react'
import { useRequest } from 'ahooks';
import { ApiPort } from '$ACTIONS/API';
import { fetchRequest } from '$CONFIG/Fatch.config';

const getQrpFields = () => fetchRequest(ApiPort.QrpFields, 'GET', null);

const useQrpFields = (options) => {
    return useRequest(getQrpFields, options);
}

export default useQrpFields;
