import React, { useRef } from "react";
import { Carousel } from 'antd';
import { useSize } from 'ahooks';
import HostConfig from '$CONFIG/Host.config';
import LazyLoad from 'react-lazyload';
const CMSURL = HostConfig.Config.CMSURL;


const Banner = ( { bannerList, children} ) => {
	const bannerRef = useRef(null);
	const size = useSize(() => document.querySelector('body')) || {};
	const bodyWidth = size.width || 0;

	return (
		<>
			<section className="banner">
				<Carousel
					ref={bannerRef}
					autoplay={true}
					autoplaySpeed={4500}
					lazyLoad={true}
					dots={false}
				>
					{bannerList && bannerList.length &&
						bannerList.map((item, index) => {
							return(
								<LazyLoad key={index}>
									<div className="img_wrap">
										<img
											src={`${bodyWidth > 1023 ? item.cmsImageUrlWeb : item.cmsImageUrlMobile}`}
											className={'imgItem'}
											alt={`${bodyWidth > 1023 ? item.cmsImageUrlWebAlt : item.cmsImageUrlMobileAlt}`}
										/>
									</div>
								</LazyLoad>
							)
						})
					}
				</Carousel>
				{children}
			</section>
		</>
	)
	
}

export default Banner;
